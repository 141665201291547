import { useMemo, useState, useRef, useEffect } from 'react';
import { ArrowDownToLineIcon } from 'lucide-react';
import {
    DOWNLOAD_ATTR_FILE_ID,
    DOWNLOAD_ATTR_FILE_TITLE,
    DOWNLOAD_ATTR_PROFESSIONAL_ID,
    DOWNLOAD_ATTR_PROFESSIONAL_TITLE,
    TRACK_DOWNLOAD_CLASSNAME,
} from '@archipro-website/tracker';
import { cn } from '@rocco/utils/cn';
import { Button } from '@archipro/rocco/components/button';
import type { OverviewLoaderData } from '@modules/professional/page/OverviewPage';

export const Downloads = ({
    className,
    professional,
    downloads = [],
    showMoreLimit = 0,
}: {
    className?: string;
    professional: OverviewLoaderData['professionalOverview'];
    downloads?: OverviewLoaderData['downloads'];
    /** The max number of items to show initially. Set to 0 to show all items. */
    showMoreLimit?: number;
}) => {
    const [isShowMore, setIsShowMore] = useState(false);
    const downloadsRef = useRef<HTMLElement>(null);

    const visibleDownloads = useMemo(() => {
        if (isShowMore || showMoreLimit <= 0) {
            return downloads;
        }
        return downloads.slice(0, showMoreLimit);
    }, [downloads, showMoreLimit, isShowMore]);

    useEffect(() => {
        // Check if URL contains scrollToDownload=1
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('scrollToDownload') === '1' && downloadsRef.current) {
            // Scroll to the downloads section and center it in the viewport
            downloadsRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });

            // If downloads are collapsed and there are more than the limit, expand them
            if (
                urlParams.get('showAllDownloads') === '1' &&
                !isShowMore &&
                showMoreLimit > 0 &&
                downloads.length > showMoreLimit
            ) {
                setIsShowMore(true);
            }
        }
    }, [downloads.length, isShowMore, showMoreLimit]);

    return (
        <section ref={downloadsRef} className={className}>
            <h3 className="mb-6 text-base font-medium md:text-xl">Downloads</h3>
            <ul className="grid list-none grid-cols-1 divide-y divide-black border-b border-t border-black">
                {visibleDownloads.map((d) => (
                    <li key={d.ID}>
                        <a
                            href={d.ExternalLink ?? d.InternalLink ?? undefined}
                            target="_blank"
                            rel="noreferrer"
                            title={d.Title}
                            className={cn(
                                'flex items-center gap-x-4 py-4 text-sm md:gap-x-8 md:text-base',
                                TRACK_DOWNLOAD_CLASSNAME
                            )}
                            {...{
                                [DOWNLOAD_ATTR_FILE_ID]: d.ID,
                                [DOWNLOAD_ATTR_FILE_TITLE]: d.Title,
                                [DOWNLOAD_ATTR_PROFESSIONAL_ID]:
                                    professional.ID,
                                [DOWNLOAD_ATTR_PROFESSIONAL_TITLE]:
                                    professional.Title,
                            }}
                        >
                            <ArrowDownToLineIcon className="size-5 shrink-0 md:size-6" />
                            <span className="truncate">{d.Title}</span>
                        </a>
                    </li>
                ))}
            </ul>
            {showMoreLimit > 0 && downloads.length > showMoreLimit && (
                <div className="mt-8 flex items-center justify-center">
                    <Button
                        color="secondary"
                        onClick={() => setIsShowMore((state) => !state)}
                    >
                        {isShowMore ? 'Show less' : 'Show more'}
                    </Button>
                </div>
            )}
        </section>
    );
};
