import {
    useStyles,
    Button,
    useAppDisplayModeContext,
} from '@archipro-design/aria';
import { Link } from '@remix-run/react';
import { useState } from 'react';
import { locationHasDistance } from '@modules/professional/hook/use-locations-sort-by-distance';
import type { LocationDataType } from '@modules/professional/type/contact-page-types';
import {
    getLocationSuburbText,
    sortLocationsByLatitude,
} from '@modules/professional/util/contact-page-helper';
import { MainInfoRightSectionItem } from '@modules/professional/component/overview-tab/main-info/MainInfoRightSectionItem';
import * as S from './Locations.style';
import InfoSectionMobile from '~/modules/detail/components/info-section/InfoSectionMobile';
import { useClickToReveal } from '~/modules/root/hook/use-click-to-reveal';
import { PROFESSIONAL_SECTIONS_ANCHOR } from '~/modules/professional/util/constants';

interface LocationsProps {
    locations: LocationDataType[];
    disabled?: boolean;
    calculateDistance: () => Promise<boolean>;
    professionalID: number;
}

export const Locations: React.FC<LocationsProps> = ({
    locations,
    disabled,
    calculateDistance,
    professionalID,
}) => {
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();
    const locationCount = locations.length;
    const hasDistance = locationHasDistance(locations);
    const labelText = locationCount > 1 ? 'Locations' : 'Location';
    const [showChangeSettingText, setShowChangeSettingText] = useState(false);
    const { onRevealClick } = useClickToReveal({
        revealType: 'Location',
        revealKey: `professional-${professionalID}`,
        revealSubKey: (
            sortLocationsByLatitude(locations)[0]?.ID ?? 0
        ).toString(),
    });
    if (locationCount <= 0) {
        return null;
    }

    const locationInfo = (
        <>
            {disabled || locationCount === 1 ? (
                <div>
                    <Link
                        to={`contact#${PROFESSIONAL_SECTIONS_ANCHOR}`}
                        state={{ ID: locations[0]?.ID }}
                        prefetch="intent"
                        style={{ textDecoration: 'underline' }}
                        onClick={() => onRevealClick()}
                    >
                        View address
                    </Link>
                </div>
            ) : (
                <>
                    {hasDistance ? (
                        <div className={css(S.LocationFindText)}>
                            {`Nearest to you: `}
                            <Link
                                to={`contact{}`}
                                state={{
                                    ID: hasDistance
                                        ? locations[0]?.ID
                                        : undefined,
                                }}
                                prefetch="intent"
                                onClick={() => onRevealClick()}
                            >
                                {getLocationSuburbText(locations[0])}
                            </Link>
                        </div>
                    ) : (
                        <>
                            <Button
                                variant="text"
                                color="dark"
                                className={css(S.LocationFindButton)}
                                onClick={async () => {
                                    const hasDistance =
                                        await calculateDistance();
                                    if (!hasDistance) {
                                        setShowChangeSettingText(true);
                                    }
                                }}
                                size={16}
                                variables={{
                                    buttonPadding: 0,
                                    darkNoBKGButtonBackgroundColorHover:
                                        'transparent',
                                    darkNoBKGButtonBorderSelected:
                                        'transparent',
                                }}
                            >
                                {showChangeSettingText
                                    ? `Change your browser settings to find location nearest to you`
                                    : `Find location nearest to you`}
                            </Button>
                        </>
                    )}
                    <div className={css(S.LocationViewAllLink)}>
                        <Link
                            to={`contact#${PROFESSIONAL_SECTIONS_ANCHOR}`}
                            state={{
                                ID: hasDistance ? locations[0]?.ID : undefined,
                            }}
                            prefetch="intent"
                            onClick={() => onRevealClick()}
                        >
                            View all locations ({locationCount})
                        </Link>
                    </div>
                </>
            )}
        </>
    );

    return mobile ? (
        <InfoSectionMobile
            className={css(S.LocationSectionMobile)}
            label={labelText}
        >
            {locationInfo}
        </InfoSectionMobile>
    ) : (
        <MainInfoRightSectionItem
            label={labelText}
            className={css(S.LocationSection)}
        >
            {locationInfo}
        </MainInfoRightSectionItem>
    );
};
